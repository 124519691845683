import { Stack } from '@arrived/bricks';
import { RegisteredComponent } from '@arrived/builder.io';

import { UseGetRelatedArticlesQueryResult } from '../queries';
import { ArticlePreview } from './article';

type RelatedArticlesProps = {
  posts?: UseGetRelatedArticlesQueryResult['data'];
};

export const RelatedArticles = ({ posts }: RelatedArticlesProps) => {
  if (!posts?.length) {
    return null;
  }

  return (
    <Stack className="related-articles" justifyContent="space-between" gap="$4" flex={1} $gtXxs={{ row: true }}>
      {posts?.map((post, idx) => {
        if (!post?.data) {
          return null;
        }

        return (
          <ArticlePreview
            key={`${post.id}-${idx}`}
            post={post}
            linkProps={{
              replace: true,
              href: `/blog/${post.data.slug}`,
            }}
          />
        );
      })}
    </Stack>
  );
};

RelatedArticles.registerComponent = {
  component: RelatedArticles,
  name: 'Related Posts',
  // inputs: [
  //   {
  //     name: 'posts',
  //     type: 'list',
  //     subFields: [
  //       {
  //         name: 'title',
  //         type: 'text',
  //       },
  //       {
  //         name: 'featuredImage',
  //         type: 'file',
  //       },
  //       {
  //         name: 'slug',
  //         type: 'text',
  //       },
  //     ],
  //   },
  // ],
} satisfies RegisteredComponent;
