import { PropsWithChildren } from 'react';

import { Stack } from '@arrived/bricks';
import { htmlParser } from '@arrived/bricks-common';
import { ArrivedBuilderModels, BricksBuilderIcons, RegisteredComponent } from '@arrived/builder.io';
import { UseLinkProps, useLink } from '@arrived/router';

import { UseGetBlogArticleQueryResult } from '../../queries';
import { ArticleCard } from './ArticleCard';
import { ArticleTitleMetadata } from './ArticleTitleMetadata';

interface ArticlePreviewProps {
  variant?: 'hero' | 'preview';
  post: NonNullable<UseGetBlogArticleQueryResult['data']>;
  linkProps: UseLinkProps;
  showExcerpt?: boolean;
}

export const ArticlePreview = ({
  post,
  variant,
  linkProps: linkPropsHook,
  children,
  showExcerpt = true,
}: PropsWithChildren<ArticlePreviewProps>) => {
  const linkProps = useLink(linkPropsHook);

  if (!post.data) {
    return null;
  }

  return (
    <ArticleCard variant={variant} gap="$2" {...linkProps}>
      {post?.data.featuredImage && (
        <ArticleCard.Image title={post.data.title} featuredImage={post.data.featuredImage} flexShrink={1} />
      )}

      <Stack flex={1} gap="$2">
        <ArticleCard.Header tag="h3" flex={1}>
          {post?.data.title}
        </ArticleCard.Header>

        <ArticleTitleMetadata post={post} />

        {Boolean(post?.data.excerpt && showExcerpt) && (
          <ArticleCard.Excerpt numberOfLines={2}>{htmlParser(post?.data.excerpt)}</ArticleCard.Excerpt>
        )}
      </Stack>

      {children}
    </ArticleCard>
  );
};

ArticlePreview.registerComponent = {
  component: ArticlePreview,
  name: 'BlogPostPreview',
  image: BricksBuilderIcons.Show,
  inputs: [
    {
      name: 'post',
      type: 'reference',
      model: ArrivedBuilderModels.BLOG_ARTICLE,
    },
    {
      name: 'variant',
      type: 'enum',
      enum: ['hero', 'preview'],
    },
  ],
} satisfies RegisteredComponent;
