import { useQuery } from '@tanstack/react-query';

import { ArrivedBuilderModels, fetchOneEntry } from '@arrived/builder.io';
import { CONFIG } from '@arrived/config';

import { BuilderBlogArticleModel } from '../models';
import { builderBlogArticleQueryKeyFn } from './keys';

export const fetchBlogArticle = (slug?: string) =>
  fetchOneEntry<BuilderBlogArticleModel>({
    model: ArrivedBuilderModels.BLOG_ARTICLE,
    apiKey: CONFIG.builderApiKey,
    enrich: true,
    query: {
      data: {
        slug,
      },
    },
  });

export const useGetBlogArticleQuery = (slug?: string) =>
  useQuery({
    queryKey: builderBlogArticleQueryKeyFn(slug),
    queryFn: () => fetchBlogArticle(slug),
    enabled: !!slug,
  });

export type UseGetBlogArticleQueryResult = ReturnType<typeof useGetBlogArticleQuery>;
