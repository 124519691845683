import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { FlashList } from '@shopify/flash-list';

import { Filter, ScrollView, Stack, getTokens, getVariableValue, isWeb } from '@arrived/bricks';
import { Link, useRouter } from '@arrived/router';

import { useGetCategoriesQuery } from '../../queries';
import { useCategoriesNavigationContext } from './CategoriesNavigationContext';

const sizeTokens = getTokens().size;

export type CategoriesNavigationProps = {
  activeCategorySlug?: string;
};

const DefaultNavigationEntries = [
  {
    href: '/blog',
    name: <FormattedMessage id="blog.category.featured" />,
  },
  {
    href: '/blog/category/latest',
    name: <FormattedMessage id="blog.category.latest" />,
  },
];

export const CategoriesNavigation = () => {
  const scrollRef = useRef<
    FlashList<{
      href: string;
      name: ReactNode;
    }>
  >(null);

  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  const { activeCategorySlug, setActiveCategorySlug } = useCategoriesNavigationContext();

  const router = useRouter();

  const categoriesState = useGetCategoriesQuery();

  const categoryList = useMemo(
    () => [
      ...DefaultNavigationEntries,
      ...(categoriesState.data || [])
        .map((categoryEntry) => {
          if (!categoryEntry.data) {
            return null;
          }

          return {
            href: `/blog/category/${categoryEntry.data.slug}`,
            name: categoryEntry.data.name,
          };
        })
        .filter((x): x is Exclude<typeof x, false | null | undefined | '' | 0> => Boolean(x)),
    ],
    [categoriesState.data],
  );

  const handleFilterPress = useCallback(
    (href: string, index: number) => {
      setCurrentCategoryIndex(index);

      if (isWeb) {
        router.push(href);
      } else {
        setActiveCategorySlug(href.split('/').pop());
      }
    },
    [setActiveCategorySlug, scrollRef.current],
  );

  const isFilterActive = useCallback(
    (href: string) => {
      if (isWeb) {
        return router.asPath === href;
      }

      return activeCategorySlug === href.split('/').pop();
    },
    [activeCategorySlug, router.asPath],
  );

  // Set the current category index when the active category slug changes. This is mainly for when
  // an outside influence changes the active category slug, like the ArticleTitleMetadata component and
  // its `LinkedTag` component.
  useEffect(() => {
    if (activeCategorySlug) {
      const index = categoryList.findIndex((item) => item.href.includes(activeCategorySlug));

      if (index !== -1) {
        setCurrentCategoryIndex(index);
      }
    }
  }, [activeCategorySlug]);

  // Scroll to the current category index when it changes
  useEffect(() => {
    // This is really native only, so it will no-op on web
    scrollRef.current?.scrollToIndex({ index: currentCategoryIndex, viewOffset: 0, animated: true });
  }, [currentCategoryIndex]);

  return (
    <Stack flex={1}>
      {isWeb ? (
        <>
          {/* This allows us to hide the scrollbar on web when server rendered */}
          <style jsx global>{`
            .server-category-list {
              scrollbar-width: none;
            }
          `}</style>
          <ScrollView
            className="server-category-list"
            horizontal
            flexShrink={1}
            contentContainerStyle={{
              gap: getVariableValue(sizeTokens.$2),
            }}
          >
            {categoryList.map((item, index) => {
              const active = isFilterActive(item.href);

              return (
                <Link href={item.href} key={`category-filter-${item.href}-${index}`}>
                  <Filter active={active} labelProps={{ whiteSpace: 'nowrap' }} asChild>
                    {item.name}
                  </Filter>
                </Link>
              );
            })}
          </ScrollView>
        </>
      ) : (
        <FlashList
          data={categoryList}
          ref={scrollRef}
          horizontal
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item) => item.href}
          ItemSeparatorComponent={() => <Stack width="$2" />}
          estimatedItemSize={100}
          extraData={{
            asPath: router.asPath,
          }}
          renderItem={({ item, index }) => {
            const active = isFilterActive(item.href);

            return (
              <Filter
                active={active}
                labelProps={{ whiteSpace: 'nowrap' }}
                onPress={() => handleFilterPress(item.href, index)}
              >
                {item.name}
              </Filter>
            );
          }}
        />
      )}
    </Stack>
  );
};
