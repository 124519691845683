import {
  ArrivedBuilderModels,
  GenericBuilderBlockReference,
  GenericBuilderIcons,
  RegisteredComponent,
} from '@arrived/builder.io';
import { Link } from '@arrived/router';

import { BuilderBlogArticleModel } from '../../models';
import { ArticleCard } from './ArticleCard';
import { ArticleList } from './ArticleList';

type ArticleCategoriesListProps = {
  header: string;
  posts?: {
    post: GenericBuilderBlockReference<BuilderBlogArticleModel>;
  }[];
};

export const ArticleCategoriesList = ({ header, posts }: ArticleCategoriesListProps) => {
  return (
    <ArticleList>
      <ArticleList.Header>
        <ArticleList.Header.Title tag="h3">{header}</ArticleList.Header.Title>
      </ArticleList.Header>

      <ArticleList.Body>
        <ArticleList.Body.Items>
          {posts?.map((postRef) => {
            if (!postRef.post?.value.data) {
              return null;
            }

            const id = postRef.post.value.id;
            const { title, featuredImage, slug } = postRef.post.value.data;

            return (
              <Link key={`${id}-${slug}`} href={`/blog/${slug}`} prefetch={false}>
                <ArticleCard
                  horizontal
                  variant="preview"
                  $platform-native={{
                    pointerEvents: 'none',
                  }}
                >
                  <ArticleCard.Image title={title} featuredImage={featuredImage} maxWidth={150} />
                  <ArticleCard.Header tag="h4">{title}</ArticleCard.Header>
                </ArticleCard>
              </Link>
            );
          })}
        </ArticleList.Body.Items>
      </ArticleList.Body>
    </ArticleList>
  );
};

ArticleCategoriesList.registerComponent = {
  component: ArticleCategoriesList,
  name: 'BlogCategoryList',
  image: GenericBuilderIcons.ListThick,
  inputs: [
    { name: 'header', type: 'longText' },
    {
      name: 'posts',
      helperText: 'Choose a list of posts to display',
      type: 'list',
      subFields: [
        {
          name: 'post',
          type: 'reference',
          model: ArrivedBuilderModels.BLOG_ARTICLE,
        },
      ],
    },
  ],
  models: [ArrivedBuilderModels.PAGE, ArrivedBuilderModels.BLOG_ARTICLE],
} satisfies RegisteredComponent;
