import { ReactNode, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { useArrivedAuth0 } from '@arrived/arrived-auth0';
import { Button, ScrollView, Stack, TitleText, isWeb } from '@arrived/bricks';
import { SocialSharePopper, SocialSharePopperProps, getTextChildren, htmlParser } from '@arrived/bricks-common';
import { BuilderImage, GenericBuilderIcons, RegisteredComponent, transformBuilderImage } from '@arrived/builder.io';
import { CONFIG } from '@arrived/config';
import { Link, useRouter } from '@arrived/router';

import { UseGetBlogArticleQueryResult } from '../../queries';
import { BrowsePropertiesCTA } from '../BrowsePropertiesCTA';
import { ArticleNavigation, ArticleNavigationEntry } from './ArticleNavigation';
import { ARTICLE_SECTION_ID } from './ArticleSection';
import { ArticleTitleMetadata } from './ArticleTitleMetadata';

const StickyScrollContainer = isWeb ? Stack : ScrollView;

interface ArticleContentProps {
  children?: ReactNode;
  builderContext: {
    content: UseGetBlogArticleQueryResult['data'];
  };
}

export const ArticleContent = ({ builderContext, children }: ArticleContentProps) => {
  const router = useRouter();

  const { isAuthenticated } = useArrivedAuth0();

  const title = useMemo(() => builderContext.content?.data?.title ?? '', [builderContext.content]);
  const excerpt = useMemo(() => builderContext.content?.data?.excerpt ?? '', [builderContext.content]);
  const parsedBody = useMemo(
    () => (builderContext.content?.data?.content ? htmlParser(builderContext.content?.data?.content) : ''),
    [builderContext.content],
  );

  const sections = useMemo<ArticleNavigationEntry[]>(() => {
    if (Array.isArray(parsedBody)) {
      return parsedBody
        .filter((el) => el.props?.tag === 'h2')
        .map((el) => ({
          name: getTextChildren(el),
          id: el.props.id,
        }));
    }

    if (Array.isArray(children)) {
      const headerSections = children.filter(
        (entry) => entry.props?.block?.component.options.builder_id === ARTICLE_SECTION_ID,
      );
      return headerSections.map((entry) => {
        const options = entry.props?.block?.component?.options;
        return {
          name: options.title,
          id: options.id,
        };
      });
    }
    return [];
  }, [parsedBody, children]);

  const featuredImageUrl = useMemo(() => {
    const featuredImage = builderContext.content?.data?.featuredImage;

    if (!featuredImage) {
      return undefined;
    }

    return transformBuilderImage(featuredImage, { width: 800, height: 500 });
  }, [builderContext.content]);

  const shareConfig = useMemo<SocialSharePopperProps['config']>(
    () => [title, excerpt, 'arrived', featuredImageUrl],
    [title, excerpt, featuredImageUrl],
  );

  if (!builderContext.content) {
    return null;
  }

  return (
    <>
      {isWeb && (
        <style jsx global>{`
          .article-display-image {
            width: 100%;
            height: auto;
            object-fit: cover;
          }

          ol.blog-list,
          ul.blog-list {
            list-style-position: inside;
          }

          ol.blog-list li.blog-list-entry,
          ul.blog-list li.blog-list-entry {
            display: list-item;
            padding-left: var(--space-2);
          }

          ul.blog-list li.blog-list-entry::before {
            content: '';
            margin-left: calc(-1 * var(--space-2));
          }
        `}</style>
      )}

      <Stack gap="$6" $gtXxs={{ gap: '$8' }}>
        <Stack tag="header" gap="$3" flex={1}>
          {/* eslint-disable jsx-a11y/aria-role */}
          {/* @ts-ignore role needs to be unset via null */}
          <TitleText tag="h1" role={null} token="title.heading.xlarge" $gtSm={{ token: 'title.display.small' }}>
            {title}
          </TitleText>

          <ArticleTitleMetadata post={builderContext.content} />
        </Stack>

        <Stack centered>
          <Stack
            asChild={!isWeb}
            className="article-display-image"
            width="100%"
            flex={1}
            maxWidth={800}
            br="$2"
            overflow="hidden"
          >
            <BuilderImage aspectRatio="4/2.5" contentFit="fill" source={{ uri: featuredImageUrl! }} alt={title} />
          </Stack>
        </Stack>

        <StickyScrollContainer>
          <Stack tag="main" flex={1} gap="$8" flexDirection="column-reverse" $gtSm={{ flexDirection: 'row' }}>
            <Stack flex={1}>
              <Stack className="blog-content">
                {children}
                {parsedBody}
              </Stack>

              <Stack className="blog-content-footer" my="$6">
                <Stack row justifyContent="space-between" alignItems="center">
                  <BrowsePropertiesCTA />
                  <SocialSharePopper config={shareConfig} url={CONFIG.publicAppRoot + router.asPath} />
                </Stack>
              </Stack>
            </Stack>

            {/*
              Disabling this on native, as we don't have a good way
              of manipulating the child scroll container just yet.

              Things I've tried:
              - Nested scroll view with ref, no response from `scrollTo`
              - Parent scroll view to any `y` position, no response from `scrollTo`
              - Nested `SectionList`, crashes the app if `parsedBody` is not an array

              Things I want to try:
              - `react-dom` my way down to each `h2` and set positions based on that, still
              doesn't solve the issue of the parent scroll view not responding to `scrollTo`

              Sticky works by doing `Stack` on web instead of `ScrollView`. The browser gives overflow
              to the right element that way.
            */}
            {Boolean(isWeb && sections.length) && (
              <Stack
                $gtSm={{
                  maxWidth: 300,
                  alignItems: 'flex-start',
                  alignSelf: 'flex-start',
                  position: 'sticky' as 'relative',
                  top: 150,
                }}
                $gtMd={{
                  maxWidth: 350,
                }}
                width="100%"
                gap="$4"
              >
                <ArticleNavigation sections={sections} />

                {!isAuthenticated && (
                  <Link href="/register" prefetch={false}>
                    <Button variant="outlined">
                      <FormattedMessage id="auth.signup" />
                    </Button>
                  </Link>
                )}
              </Stack>
            )}
          </Stack>
        </StickyScrollContainer>
      </Stack>
    </>
  );
};

ArticleContent.registerComponent = {
  component: ArticleContent,
  name: 'Blog Post',
  image: GenericBuilderIcons.MosaicLayout,
  canHaveChildren: true,
  shouldReceiveBuilderProps: {
    builderContext: true,
  },
  inputs: [
    {
      name: 'topSection',
      type: 'blocks',
      hideFromUI: true,
      helperText: 'This is an editable region.',
      defaultValue: [
        {
          '@type': '@builder.io/sdk:Element',
          component: {
            name: 'Bricks:Text',
            options: {
              text: 'Top section content...',
            },
          },
        },
      ],
    },
  ],
  models: ['blog-article'],
} satisfies RegisteredComponent;
